let ConstantComponent = {};

ConstantComponent['development'] = {} //require('./config.development').ConstantVariable;
ConstantComponent['production']  = {} //require('./config.production').ConstantVariable;
ConstantComponent['process'] = {};
 
export const modifyConfig = (data, env) => {
    ConstantComponent[process.env.NODE_ENV] = data
    ConstantComponent['process'] = {
        env: env
    }
}

export default ConstantComponent