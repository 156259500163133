// Common Images
import LogoLoadingImg from './../assets/img/redirect-loader.gif'; // Loading gif as logo
import LoadingImg from './../assets/images/loader.gif'; // Loading gif
import NoApplication from './../assets/images/no-application.jpg'  // No Application Found
import SessionExpired from './../assets/images/session-icon.svg' // Session Expired
import Logo from './../assets/img/logo.png'


// Service Images
import { ReactComponent as ImgLand } from './../assets/images/land.svg'; 
import { ReactComponent as ImgPlan } from './../assets/images/planning_survey.svg';
import { ReactComponent as ImgRealEstateDevelop } from './../assets/images/real_estate_development.svg';
import { ReactComponent as ImgRealEstate } from './../assets/images/real_estate.svg';
import { ReactComponent as ImgSocial } from './../assets/images/social_housing.svg';
import socialHousing from './../assets/images/social-housing.svg';
import Req_land from './../assets/images/land-request-icon.svg';

// import EservicesImg from "./../assets/img/home-eservices.jpg"
import EservicesImg1 from "./../assets/img/home-eservice.jpg"
import EservicesImg from "./../assets/img/home-eservice-1.jpeg"

import LandDevelopment from './../assets/img/land-development.png'
import LandDevelopmentWhite from './../assets/img/land-development-white.png'
import RealEstate from './../assets/img/real-estate.png'
import RealEstatetWhite from './../assets/img/real-estate-white.png'
import Planning from './../assets/img/planning.png'
import PlanningWhite from './../assets/img/planning-white.png'
import LandAffairs from './../assets/img/land-affairs.png'
import LandAffairsWhite from './../assets/img/land-affairs-white.png'
import SocialHousing from './../assets/img/social-housing.png'
import SocialHousingWhite from './../assets/img/social-housing-white.png'

import Manual from './../assets/img/user-guide.png'
import ManualWhite from './../assets/img/user-guide-white.png'

import Support from './../assets/img/support.png'
import SupportWhite from './../assets/img/support-white.png'

// Portal Images 
import NewsHeader from './../assets/images/news-header.jpg';
import HistoryBG from './../assets/img/banner/history-banner.jpg'
import PublicationBG from './../assets/img/banner/books-publication.jpg'
import NewsBG from './../assets/img/banner/news.jpg'
import Sitemap from './../assets/img/banner/site-map.jpg'
import ProjectsBG from './../assets/img/banner/projects.jpg'
import VisionBG from './../assets/img/banner/mission-banner.jpg'
import VideoGalleryBG from './../assets/img/banner/video-gallery.jpg'
import PhotoGalleryBG from './../assets/img/banner/photo-gallery.jpg'
import OrgChartBG from './../assets/img/banner/organization-structure.jpg'
import SpecilitiesBG from './../assets/img/banner/specialities.jpg'
import ContactBG from './../assets/img/banner/locate-us.jpg'
import PhonebookBG from './../assets/img/banner/phone-book.jpg'
import AnnouncementBG from './../assets/img/banner/announcements.jpg'
import StatisticsBG from './../assets/img/banner/statistics.jpg'
import InformationCenterBG from './../assets/img/banner/information-center.jpg'
import LegislationsBG from './../assets/img/banner/legislation.jpg'
import ServiceCatelogueBG from './../assets/img/banner/service-catalogue.jpg'
import EServiceCatelog from './../assets/img/banner/electronic-payment-policy.jpg'
import TermsofUse from './../assets/img/banner/terms-of-use.jpg'
import Policies from './../assets/img/banner/e_policies.jpg';
import Strategy from './../assets/img/banner/strategy.png';
import Nophoto from './../assets/images/No-photo.jpg';
import erediness from './../assets/img/svg/e-rediness report.svg';
import legislation from './../assets/img/svg/legislation.svg';
import statistic from './../assets/img/svg/statistic.svg';

import Img1975 from "./../assets/img/img-1975.png"
import Img1976 from  "./../assets/img/img-1976.png"
import Img1982 from "./../assets/img/img-1982.png"
import Img1984 from "./../assets/img/img-1984.png"
import Img1985 from "./../assets/img/img-1985.png"

// Header
import { ReactComponent as Language } from './../assets/img/svg/accessibility.svg';
import { ReactComponent as Search } from './../assets/img/svg/search.svg';
import { ReactComponent as Speak } from './../assets/img/svg/text to speak.svg';

import FontSmall from "./../assets/img/font-slider-small.svg"
import FontMedium from "./../assets/img/font-size-btn.png"
import FontLarge from "./../assets/img/font-slider-large.svg"

import Home from './../assets/img/home.png'
import TollFree from "./../assets/img/toll-free.png"

import QuickLinks from './../assets/img/quick-links.png'
import QuickLinksAr from './../assets/img/quick-links-arabic.png'



// Footer 
import FooterLogo from './../assets/img/Footer_Logo.png'
import FooterIcon from "./../assets/img/footer-icon.png"
import Twitter from './../assets/img/twitter.png'
import TwitterFoot from './../assets/img/twitter_foot.png'
import CustomerSupport from './../assets/img/customer-service.png'
import Facebook from './../assets/img/facebook.png'
import Instagram from './../assets/img/Instagram.png'
import Youtube from './../assets/img/youtube.png'
import Certificate from './../assets/img/certificate.png'
import ChatIcon from './../assets/img/chat-icon.png'
import DTIcon from './../assets/img/dt-icon.png'
import ProfileLG from "./../assets/img/profile-lg.png"
import SummaryIcon from './../assets/img/summary-icon.png'

// Sub Service Images
import NameChange from './../assets/images/NameChange.svg';

// Sub Service Images with Service Name
import LN1_img from './../assets/images/land-request-icon.svg';

import LN01A_img from './../assets/images/residential.svg';
import LN01B_img from './../assets/images/commercial.svg';
import LN01C_img from './../assets/images/industrial.svg';

import LN3_img from './../assets/images/ln_3.svg';
import LN4_img from './../assets/images/usefruct.svg';
import LN5_img from './../assets/images/LN5_renewal-usufruct.svg';
import LN6_img from './../assets/images/LN6_cancel-usufruct.svg';

//real-estate
import RN1_img from './../assets/images/RN1.svg';
import RN01A_img from './../assets/images/lost.svg';
import RN01A_img_header from './../assets/images/lost_header.svg';
import RN01B_img from './../assets/images/damaged.svg';
import RN01B_img_header from './../assets/images/damaged_header.svg';
import RN01C_img from './../assets/images/NameChange.svg';
import RN01C_img_header from './../assets/images/NameChange_header.svg';
import RN01D_img from './../assets/images/ListOfRecords.svg';
import RN01D_img_header from './../assets/images/ListOfRecords_header.svg';
import RN01E_img from './../assets/images/RequestAccess.svg';
import RN01E_img_header from './../assets/images/RequestAccess_header.svg';
import RN01F_img from './../assets/images/MissingData.svg';
import RN01F_img_header from './../assets/images/MissingData_header.svg';

import RN2_img from './../assets/images/freeze.svg';
import RN2_img_header from './../assets/images/freeze_header.svg';
import RN3_img from './../assets/images/unfreeze.svg';
import RN3_img_header from './../assets/images/unfreeze_header.svg';
import RN4_img from './../assets/images/RN4-Regmortgagecontract.svg';
import RN5_img from './../assets/images/RN5-Mortgage-transfer.svg';
import RN6_img from './../assets/images/RN6-ReleaseContract.svg';
import RN7_img from './../assets/images/RN7-Inheritance.svg';

import RN07A_img from './../assets/images/split.svg';
import RN07B_img from './../assets/images/withoutsplit.svg';

import RN8_img from './../assets/images/RN8-Testament.svg';
import RN9_img from './../assets/images/RN9-Endowment.svg';
import RN9_img_header from './../assets/images/RN9-Endowment_header.svg';
import RN10_img from './../assets/images/RN10-granting.svg';
import RN12_img from './../assets/images/RN12-Split.svg';
import RN13_img from './../assets/images/RN13-Sale-contract-for-citizen.svg';
import RN13A_img_citizen from './../assets/images/RN13_omani.svg';
import RN13A_img_citizen_header from './../assets/images/RN13_omani_header.svg';
import RN13B_img_GCC from './../assets/images/RN13_GCC.svg';
import RN13B_img_GCC_header  from './../assets/images/RN13_GCC_header.svg';
import RN13C_img_Broker from './../assets/images/RN13_broker.svg';
import RN13C_img_Broker_header from './../assets/images/RN13_broker_header.svg';
import RN14_img from './../assets/images/RN14-Landexchange.svg';
import RN15_img from './../assets/images/RN15-Usufruct.svg';
import RN16_img from './../assets/images/RN16-Usufruct Exchange.svg';
import RN17_img from './../assets/images/RE-Usufruct-Cancel-RN17.svg';
import RN15B_img from './../assets/images/RE-Usufruct-Renewal-RN16.svg';
import RN16A_img from './../assets/images/Rn16A-Real-Estate-and-Real-Estate.svg';
import RN16B_img from './../assets/images/Rn16B-Beneficiary-to-Beneficary.svg';
import RN18_img from './../assets/images/Register_final_judgement.svg';
import RN18A_img from './../assets/images/Final Court Judgement - Heirs - With Splitting.svg';
import RN18B_img from './../assets/images/Final Court Judgement - Heirs - Without Splitting.svg';
import RN18C_img from './../assets/images/Final Court Judgement - Granting.svg';
import RN18D_img from './../assets/images/Court Judgement - Selling to citizens.svg';
import RN18E_img from './../assets/images/Court Judgement - Selling to GCCForeigners.svg';
import RN11_img from './../assets/images/ProvideAccessThirdParty.svg';
import RN11A_img from './../assets/images/Provision-of-access-to-RE-Ownership.svg';
import RN11B_img from './../assets/images/Provision-of-access-to-RE-e-File.svg';
import RN22_img from './../assets/images/Sale-Contract-Business.svg';

// Banners
import EService_Banner from './../assets/images/banner/banner_eservice.jpg';
import PostOffice_Banner from './../assets/images/banner/banner_Post-Office.jpg';
import SanadOffice_Banner from './../assets/images/banner/banner_sanad.jpg';
import Company_Banner from './../assets/images/banner/banner_company-registration.jpg';
import Wakf_Banner from './../assets/images/banner/banner_Waqf-Registration.jpg';

import RealEstate_Banner from './../assets/images/banner/banner_real_estate.jpg';
import RN1_RealEstate_Banner from './../assets/images/banner/banner_request_t_access_my_realestate_information.jpg';
import RN1_Service_Banner from './../assets/images/banner/banner_registration_of_realestate_ownership.jpg';
import RN2_Service_Banner from './../assets/images/banner/banner_freezing_realestate.jpg';
import RN3_Service_Banner from './../assets/images/banner/banner_unfreezing_realestate.jpg';
import RN9_Service_Banner from './../assets/images/banner/banner_endowment.jpg';
import RN13_Service_Banner from './../assets/images/banner/banner_registration_of_sale_contract.jpg';

// User-icons
import Proxy_img from './../assets/images/Proxy.svg';
import Sanad_img from './../assets/images/Sanad.svg';
import PostOffice_img from './../assets/images/Post Office.svg';
import Wakf_img from './../assets/images/Waqf Registration.svg';

// Planning-survey
import PS7_img from './../assets/images/psn7.svg';
import PS04_img from './../assets/images/psn4.svg';
import PS05_img from './../assets/images/psn5.svg';
import PS6_img from './../assets/images/Planning Appropriation.svg';
import PS3_img from './../assets/images/changeuse.svg';
import Commercial_img from './../assets/images/commercial.svg';
import PS01_img from './../assets/images/psn1.svg';
import PS01A_img from './../assets/images/divide.svg';
import PS01B_img from './../assets/images/merge.svg';
import PS01C_img from './../assets/images/separation.svg';

// WorkItem Image
import ImgWorkItem from './../assets/images/share-workitem.svg';

// Profile Images
import MyWorkItemIcon from './../assets/images/myworkitem.svg';
import MyLandIcon from './../assets/images/Land_sv.svg';
import SettingsIcon from './../assets/images/settings.svg';
import DevicesIcon from './../assets/images/devices.svg';
import SupportIcon from './../assets/images/support.svg';
import PrimaryUser from './../assets/images/primaryUser.svg';
import ProfileXs from './../assets/img/profile-xs.png'
import DownArrowGray from './../assets/img/down-arrow-gray.png'

// Payment
import PaymentImage from './../assets/images/success.jpg';
import PaymentSuccessImage from './../assets/images/thnkyou-img.png';
import PaymentFailureImage from './../assets/images/payment-failure.jpg';
import PayLaterImg from './../assets/images/pay-later.jpg';
import PayImg from './../assets/images/pay.jpg';

// Status Images
import CompleteImg from './../assets/images/complete.png';
import CheckCircle from './../assets/images/check_circle.svg'
import Delete from './../assets/images/delete.png';
import FileImage from './../assets/images/file.png';
import Upload from './../assets/images/upload.png';
import Hand from './../assets/images/attention.svg';
import WarningAlertImg from './../assets/images/Alerts/warning.svg';
import Approve from './../assets/images/approve.png';
import Reject from './../assets/images/reject.png';
import HandImg from './../assets/images/hand.jpg';

import ONSSImg from './../assets/images/ONSS.png';

import MuscatThink from './../assets/images/MuscatThink.jpeg';
import MuscatThinkAR from './../assets/images/MuscatThinkAr.png';
// import MeetPlanner from './../assets/images/MeetPlanner.jpeg';
// import MeetPlannerAr from './../assets/images/MeetPlannerAr.png';
import Survey from './../assets/images/Survey.jpeg';
import Goals from './../assets/images/Goals.jpg';
import GoalsAr from './../assets/images/GoalsAr.png';
import Pillar1 from './../assets/images/Pillar1.jpg';
import Pillar1Ar from './../assets/images/Pillar1Ar.png';
import Pillar2 from './../assets/images/Pillar2.jpg';
import Pillar2Ar from './../assets/images/Pillar2Ar.png';
import Pillar3 from './../assets/images/Pillar3.jpg';
import Pillar3Ar from './../assets/images/Pillar3.jpg';
import Pillar4 from './../assets/images/Pillar4.jpg';
import Pillar4Ar from './../assets/images/Pillar4Ar.png';
import Pillar5 from './../assets/images/Pillar5.jpg';
import Pillar5Ar from './../assets/images/Pillar5Ar.png';

// Cloud 
//import CloudUpload from './../assets/images/cloud_upload.svg'
import DocumentImg from './../assets/images/document.svg';

// Login Images
import PKIBG from './../assets/images/insert.gif';
import PKISIMBG from './../assets/images/pki-enabled-sim.gif';
import PKISIMBGEN from './../assets/images/pki-enabled-sim-English.gif';
import GCCHousingFormBanner from './../assets/images/inner_banner_en.png';

const Images = {
    Logo,
    LogoLoadingImg,
    LoadingImg,
    NoApplication,
    SessionExpired,

    PKIBG,
    PKISIMBG,
    PKISIMBGEN,

    NewsHeader,
    HistoryBG,
    PublicationBG,
    NewsBG,
    Sitemap,
    ProjectsBG,
    VisionBG,
    VideoGalleryBG,
    PhotoGalleryBG,
    OrgChartBG,
    SpecilitiesBG,
    ContactBG,
    PhonebookBG,
    AnnouncementBG,
    StatisticsBG,
    InformationCenterBG,
    LegislationsBG,
    ServiceCatelogueBG,
    EServiceCatelog,
    TermsofUse,
    Policies,
    Strategy,
    Nophoto,
    erediness,
    legislation,
    statistic,

    Img1975,
    Img1976,
    Img1982,
    Img1984,
    Img1985,

    ONSSImg,
    MuscatThink,
    MuscatThinkAR,
    // MeetPlanner,
    // MeetPlannerAr,
    Survey,
    Goals,
    GoalsAr,
    Pillar1,
    Pillar1Ar,
    Pillar2,
    Pillar2Ar,
    Pillar3,
    Pillar3Ar,
    Pillar4,
    Pillar4Ar,
    Pillar5,
    Pillar5Ar,

    Home,
    TollFree,
    Language,
    Search,
    Speak,
    FontSmall,
    FontMedium,
    FontLarge,
    QuickLinks,
    QuickLinksAr,

    FooterLogo,
    FooterIcon,
    Twitter,
    TwitterFoot,
    CustomerSupport,
    Facebook,
    Instagram,
    Youtube,
    Certificate,
    ChatIcon,
    DTIcon,
    ProfileLG,
    SummaryIcon,

    CompleteImg,
    CheckCircle,
    Delete,
    FileImage,
    Upload,
    Hand,
    WarningAlertImg,
    Approve,
    Reject,
    HandImg,

    //CloudUpload,
    DocumentImg,

    ImgLand,
    ImgPlan,
    ImgRealEstateDevelop,
    ImgRealEstate,
    ImgSocial,
    socialHousing,

    EservicesImg,
    EservicesImg1,
    LandDevelopment,
    LandDevelopmentWhite,
    RealEstate,
    RealEstatetWhite,
    Planning,
    PlanningWhite,
    LandAffairs,
    LandAffairsWhite,
    SocialHousing,
    SocialHousingWhite,
    Manual,
    ManualWhite,

    EService_Banner,
    PostOffice_Banner,
    SanadOffice_Banner,
    Company_Banner,
    Wakf_Banner,
    RealEstate_Banner,
    RN1_RealEstate_Banner,
    RN1_Service_Banner,
    RN2_Service_Banner,
    RN3_Service_Banner,
    RN9_Service_Banner,
    RN13_Service_Banner,
    Proxy_img,
    Sanad_img,
    PostOffice_img,
    Wakf_img,
    PS7_img,
    PS04_img,
    PS05_img,
    PS6_img,
    PS3_img,
    Commercial_img,
    PS01_img,
    PS01A_img,
    PS01B_img,
    PS01C_img,
    
    ImgWorkItem,

    MyWorkItemIcon,
    MyLandIcon,
    SettingsIcon,
    DevicesIcon,
    SupportIcon,
    PrimaryUser,
    ProfileXs,
    DownArrowGray,
    
    Req_land,
    NameChange,

    LN1_img,

    LN01A_img,
    LN01B_img,
    LN01C_img,

    LN3_img,
    LN4_img,
    LN5_img,
    LN6_img,


    RN1_img,
    RN2_img,
    RN2_img_header,
    RN3_img,
    RN3_img_header,
    RN4_img,
    RN5_img,
    RN6_img,
    RN7_img,
    RN8_img,
    RN9_img,
    RN9_img_header,
    RN10_img,
    RN12_img,
    RN13_img,
    RN14_img,
    RN15_img,
    RN16_img,
    RN17_img,
    RN18_img,
    RN11_img,
    RN22_img,

    RN01A_img,
    RN01A_img_header,
    RN01B_img,
    RN01B_img_header,
    RN01C_img,
    RN01C_img_header,
    RN01D_img,
    RN01D_img_header,
    RN01E_img,
    RN01E_img_header,
    RN01F_img,
    RN01F_img_header,

    RN07A_img,
    RN07B_img,

    RN11A_img,
    RN11B_img,

    RN13A_img_citizen,
    RN13A_img_citizen_header,
    RN13B_img_GCC,
    RN13B_img_GCC_header,
    RN13C_img_Broker,
    RN13C_img_Broker_header,

    RN15B_img,

    RN16A_img,
    RN16B_img,

    RN18A_img,
    RN18B_img,
    RN18C_img,
    RN18D_img,
    RN18E_img,

    PaymentImage,
    PaymentSuccessImage,
    PaymentFailureImage,
    PayLaterImg,
    PayImg,
    
    Support,
    SupportWhite,
    GCCHousingFormBanner

}

export default Images;