import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './config/i18n/i18n';
import "react-stepzilla/src/css/main.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "./assets/css/icon-style.css";
import './assets/css/moh-style.css';
import './assets/css/App.css';
import LoadUtils from './loadUtilz.jsx';
import CryptoJS from 'crypto-js';
import Images from './config/Images'
import { modifyConfig } from './config/config.component';
import { Suspense } from 'react';

var JsonFormatter = {
  stringify: function (cipherParams) {
    // create json object with ciphertext
    var jsonObj = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Hex) };

    // optionally add iv or salt
    if (cipherParams.iv) {
      jsonObj.iv = cipherParams.iv.toString();
    }

    if (cipherParams.salt) {
      jsonObj.s = cipherParams.salt.toString();
    }

    // stringify json object
    return JSON.stringify(jsonObj);
  },
  parse: function (jsonStr) {
    // parse json string
    var jsonObj = JSON.parse(jsonStr);

    // extract ciphertext from json object, and create cipher params object
    var cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Hex.parse(jsonObj.ct)
    });

    // optionally extract iv or salt
    if (jsonObj.iv) {
      cipherParams.iv = CryptoJS.enc.Hex.parse(jsonObj.iv);
    }

    if (jsonObj.s) {
      cipherParams.salt = CryptoJS.enc.Hex.parse(jsonObj.s);
    }

    if (jsonObj.auth) {
      cipherParams.auth = CryptoJS.enc.Hex.parse(jsonObj.auth);
    }

    return cipherParams;
  }
};


const App = React.lazy(() => import('./App'));

const Routing = () => {

  const [isConfgurationLoaded, setIsConfgurationLoaded] = useState(false)
  //const [isdUser, setISDUser] = useState(true);
  const [isDrawNav, setDrawNav] = useState(false);

  useEffect(() => {

    // if(process.env.NODE_ENV === 'production'){
      // If Need to remove all the console log from build
      // window.console.log = function(){}
    // }
    function getActionHelperCode(){
      var key = CryptoJS.enc.Hex.parse("000102030405060708090a0b0c0d0e0f");
      var iv = CryptoJS.enc.Hex.parse("101112131415161718191a1b1c1d1e1f");

      var decrypted =  CryptoJS.AES.decrypt(process.env.REACT_APP_ACTION_HELPER, key, { iv:iv,
        mode: CryptoJS.mode.CBC,  
        padding: CryptoJS.pad.Pkcs7 
      })
      return decrypted.toString(CryptoJS.enc.Utf8)
    }
   
    async function getConfig() {

      let Base_URL = window.location.protocol + "//" + window.location.host // It should be with port
      if(window.location.hostname === "localhost"){
        //Base_URL = 'http://localhost:8080/ConfigurationLoader' // War 
        Base_URL = 'http://localhost:8082' // Local bundler
      }

      if(process.env.NODE_ENV === 'production'){
        Base_URL = Base_URL + '/ConfigurationLoader'
      }
      LoadUtils.checkCookieData("client_id"); // don't remove
      fetch(`${Base_URL}/config_portal`).then(response => response.text()).then(response => {
         fetch(`${Base_URL}/env_portal`).then(envresponse => envresponse.text()).then(envresponse => {
          
          const ciphertext = atob(response)
          const actionCode = getActionHelperCode(process.env.REACT_APP_ACTION_HELPER)

          // Decrypt
          var bytes = CryptoJS.AES.decrypt(ciphertext, actionCode, {
            format: JsonFormatter
          });
          const responseData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          const ciphertext1 = atob(envresponse)

        
          // Decrypt
          var bytes1 = CryptoJS.AES.decrypt(ciphertext1, actionCode, {
            format: JsonFormatter
          });
          let envresponseData = JSON.parse(bytes1.toString(CryptoJS.enc.Utf8));
          if (envresponseData !== undefined && responseData !== undefined) {

            // This is local server config
            // for (const key in responseData) {
            //   // console.log("typeof(responseData[key])",)
            //   if (responseData[key] && typeof(responseData[key]) === "string" && responseData[key].match("http://34.225.130.79:11095/")) {
            //     // console.log("key val",responseData[key])
            //     let stringVal = responseData[key].replace("http://34.225.130.79:11095/","http://localhost:8079/")
            //     // console.log("key val",stringVal)
            //     responseData[key] = stringVal
            //   }
            // }

            // console.log("responseData 11111111",responseData)

            modifyConfig(responseData, envresponseData)
            setIsConfgurationLoaded(true)
          }
        })
      })
    }
    
    function resize(){
      setDrawNav(window.innerWidth <= 760)
    }

    getConfig()
    window.addEventListener("resize", resize.bind(this));
    resize();
    return ()=>{
      window.removeEventListener("resize", resize.bind(this));
    }
  }, [])


  let imgLoadingDiv =
    <div style={{ textAlign: "center", marginTop: "10%" }}>
      <img alt="loader" src={Images.LogoLoadingImg} />
    </div>
  // <div class="cycles">
  //   <div id="cycle1" class="cycle"></div>
  //   <div id="cycle2" class="cycle"></div>
  //   <div id="cycle3" class="cycle"></div>
  //   <div id="cycle4" class="cycle"></div>
  //   <div id="cycle5" class="cycle"></div>
  // </div>
  // {/* <div class="loader-bg">
  //   <div class="moon">
  //     <div class="shadow"></div>
  //   </div>
  // </div> */}
  // <img src={loadingImg} alt="loadimg" style={{ marginLeft: "auto", marginRight: "auto", display: "block", marginBottom: "4%", marginTop: "4%" }} />


  return (
    isConfgurationLoaded ?
      <Suspense fallback={<div>{imgLoadingDiv}</div>}>
        <App isdUser={true} isDrawNav={isDrawNav}/>
      </Suspense>
      : <div>{imgLoadingDiv}</div>
  )
}


ReactDOM.render(<Routing />, document.getElementById('root'))