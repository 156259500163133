  function setCookieWithExpiry(name) {
    try {
        let cookie_value = getUniqueKeyClientID();
        // document.cookie = `client_id=${cookie_value}; expires=${new Date(new Date().getTime()+1000*60*60*24*365).toGMTString()}; path=/`;
        setCookieData(name, cookie_value, 365);

    } catch (error) {
        console.log("MOH ~ error:", error)
    }
  }

  function getUniqueKeyClientID() {
    let timestamp = new Date().getUTCMilliseconds();
    let randomNumber =  Math.floor(Math.random() * (9999999999 - 1000000000 + 1)) + 1000000000;
    return timestamp +new Date().getSeconds().toString() + new Date().getMinutes().toString() + new Date().getHours().toString() + new Date().getDate().toString() + ("0" + (new Date().getMonth() + 1)).slice(-2) + randomNumber;
  }

  const setCookieData = (name, value, daysToLive) => {
    let cookie = name + "=" + encodeURIComponent(value);
    
    if(typeof daysToLive === "number") {
  
        cookie += "; max-age=" + (daysToLive*24*60*60) +"; path=/";
        
        document.cookie = cookie;
    }
    return true;
}
const getCookieData = (name) => {

let cookieArr = document.cookie.split(";");

// Loop through the array elements
for(let i = 0; i < cookieArr.length; i++) {
    let cookiePair = cookieArr[i].split("=");
    
 
    if(name === cookiePair[0].trim()) {
        // Decode the cookie value and return
        return decodeURIComponent(cookiePair[1]);
    }
}


return null;
}
const checkCookieData = (name) => {

let cookieName = getCookieData(name);
    if(cookieName === "" || cookieName === null) {
        // Set cookie using our custom function
        // alert("add Cookie data " + cookieName);
        setCookieWithExpiry(name);
    } else {
    //   alert("Welcome again ----, " + cookieName);
    //  console.log("loaded")
    }
}

const LoadUtils = {
    checkCookieData
}
export default LoadUtils;